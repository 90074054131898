import React, {useCallback, useEffect, useState} from 'react'

import useSWR from 'swr'
import cn from 'classnames'
import {Image} from 'react-datocms'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'
import useEmblaCarousel from 'embla-carousel-react'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import settings from '@festi/common/constants/settings'
import {fluidRange} from '@festi/common/utils/styles'
import {useUserPrices} from '@festi/common/utils/rest'
import {handleRestResponse, restApi} from '@festi/common/api/rest'
import {Page_page_content_CarouselBlockRecord} from '@festi/common/api/datocms/types/Page'

import {BlockWrapper} from 'src/components/layout'
import NewProductCard from 'src/components/cards/redesign/NewProductCard'

const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  z-index: -1;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${fluidRange('padding-top', '24px', '38px')};
  ${fluidRange('padding-left', '16px', '50px')};
  ${fluidRange('padding-bottom', '16px', '12px')};
`

const Title = styled.div`
  position: relative;
  ${fluidRange('font-size', '20px', '24px')};
  ${fluidRange('line-height', '26px', '30px')};
  font-weight: 500;
  color: ${palette('blue')};
  padding: 4px;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    transform: rotate(-1.5deg);
    z-index: -1;
    background-color: ${palette('white')};
  }
`
const BlockContainer = styled.div`
  width: 100%;
  max-width: 1616px;
  margin-inline: auto;

  ${media.sm`
    padding-inline: 16px;
  `}
`

const Wrapper = styled.div`
  position: relative;
  min-height: 546px;
`

const CarouselContainer = styled.div`
  margin-left: auto;
  padding: 0 0 28px 0;
  max-width: 1192px;

  ${media.sm`
    padding: 0 20px 40px 20px;
  `}
`

const Carousel = styled.div`
  overflow: hidden;
`

const Viewport = styled.div`
  padding-block: 12px;
  border-radius: 0;
  background-color: #f9f9fae5;

  ${media.sm`
    border-radius: 12px;
  `}
`

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  ${fluidRange('top', '24px', '40px')};
  right: 24px;
  gap: 4px;
`

const Button = styled.button`
  display: none;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${palette('white')};
  cursor: pointer;

  ${media.sm`
    display: flex;
  `}

  &:hover {
    background-color: ${palette('ui5Solid')};
    border-color: ${palette('ui10Solid')};
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`

const EmblaContainer = styled.div`
  display: flex;
`

const EmblaSlider = styled.div`
  flex: 0 0 94%;
  min-width: 0;
  padding: 0 4px;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }

  ${media.sm`
    flex: 0 0 47.5%;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }

  `}

  ${media.md`
    flex: 0 0 calc(100% / 3 - 2px);
    padding: 0 5px;
  `}
`

const Progress = styled.div`
  position: relative;
  display: none;
  width: 100%;
  height: 2px;
  margin-top: 14px;
  overflow: hidden;
  background-color: ${palette('ui50Solid')};

  ${media.sm`
    display: flex;
  `}
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: 2px;
  box-shadow: inset 0 0 0 0.2rem ${palette('ui10Solid')};
`

function EmblaItem({children}: {children: React.ReactNode}) {
  return <EmblaSlider>{children}</EmblaSlider>
}

interface EmblaProps {
  children: React.ReactNode
}

function EmblaCarousel({children}: EmblaProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: 'start',
    containScroll: 'trimSnaps',
  })

  const [scrollProgress, setScrollProgress] = useState(0)

  const onScroll = useCallback(() => {
    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()))
    setScrollProgress(progress * 100)
  }, [emblaApi])

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
    }
  }, [emblaApi])

  useEffect(() => {
    if (!!emblaApi) {
      onSelect()
      onScroll()
      emblaApi.on('select', onSelect)
      emblaApi.on('reInit', onSelect)
      emblaApi.on('scroll', onScroll)
    }
  }, [emblaApi, onSelect, onScroll])

  return (
    <>
      <ButtonWrapper>
        <Button
          className={cn('embla__prev', {disabled: prevBtnDisabled})}
          onClick={() => emblaApi.canScrollPrev() && emblaApi.scrollPrev()}
        >
          <ArrowLeftSLineIcon />
        </Button>
        <Button
          className={cn('embla__next', {disabled: nextBtnDisabled})}
          onClick={() => emblaApi.canScrollNext() && emblaApi.scrollNext()}
        >
          <ArrowRightSLineIcon />
        </Button>
      </ButtonWrapper>

      <Viewport>
        <Carousel className="embla" ref={emblaRef}>
          <EmblaContainer>{children}</EmblaContainer>
        </Carousel>
      </Viewport>

      <Progress>
        <ProgressBar
          className="embla__progress_bar"
          style={{transform: `translate3d(${scrollProgress}%,0px,0px)`}}
        />
      </Progress>
    </>
  )
}

export default function CarouselBlock({
  title,
  image,
  products,
}: Page_page_content_CarouselBlockRecord): JSX.Element {
  const skuMap = products?.map((p) => p?.sku)

  const {data: variants} = useSWR(
    [skuMap, 'variants'],
    ([skus]) =>
      handleRestResponse(
        restApi.productVariantsList({
          sku: [skus.join(',')],
          channel: settings.channel,
        }),
      ),
    {revalidateOnFocus: false},
  )

  const {data: userPrices} = useUserPrices(skuMap)

  return (
    <BlockWrapper>
      <BlockContainer>
        <Wrapper>
          <BackgroundImage
            data={image?.responsiveImage}
            objectFit="cover"
            layout="fill"
          />

          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>

          <CarouselContainer>
            {variants?.results?.length !== 0 && (
              <EmblaCarousel>
                {variants?.results?.map((variant) => (
                  <EmblaItem key={variant.id}>
                    <NewProductCard
                      variant={variant}
                      image={{
                        url: variant.firstImage.image.webpS,
                        jpg: variant.firstImage.image.jpgS,
                        alt: variant.firstImage.alt,
                      }}
                      userPrice={userPrices?.[variant.sku]}
                    />
                  </EmblaItem>
                ))}
              </EmblaCarousel>
            )}
          </CarouselContainer>
        </Wrapper>
      </BlockContainer>
    </BlockWrapper>
  )
}
