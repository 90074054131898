import React, {useState} from 'react'

import Link from 'next/link'
import {useWindowSize} from 'react-use'
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon'
import MailLineIcon from 'remixicon-react/MailLineIcon'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'

import {EmployeeListFields_employees as Employee} from '@festi/common/api/datocms/types/EmployeeListFields'
import {Expandable} from '@festi/common/components/common'
import {H4, H5} from '@festi/common/components/typography'
import {gridTheme, styledTheme} from '@festi/common/themes'
import settings from '@festi/common/constants/settings'

import {EmployeeEmail} from '../cards/EmployeeCard'

const newDesign = settings.redesign

interface Props {
  employees: Employee[]
}

interface AccordionProps {
  isOpen: boolean
}

const EmployeeTableWrapper = styled.div``

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  background-color: white;
  border: 1px solid ${palette('ui10Solid')};
  border-radius: ${newDesign ? '12px' : 'unset'};
  overflow: hidden;
`

const TableRow = styled.tr``

const TableHead = styled.th`
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 16px;
  border-bottom: 1px solid ${palette('ui10Solid')};

  &:last-child {
    width: 100px;
    text-align: center;
  }
`

const TableItem = styled.td`
  border-bottom: 1px solid ${palette('ui10Solid')};
  padding: 16px;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    &:hover {
      fill: ${palette('lightBlue')};
    }
  }
`

const EmployeeAccordionWrapper = styled.div``

const expandedCSS = css`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), inset 0px -1px 0px #6dc4f2,
    inset 0px 1px 0px #6dc4f2;
`

const Accordion = styled.div<AccordionProps>`
  background-color: white;
  box-shadow: inset 0px -1px 0px #e6e8ee, inset 0px 1px 0px #e6e8ee;
  transition: box-shadow 1s;
  margin-bottom: 8px;

  ${(props) => props.isOpen && expandedCSS}
`

const AccordionHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  border: 1px solid ${palette('ui10Solid')};
`

const BtnWrapper = styled.div`
  text-align: right;
`

const OpenBtn = styled.button`
  border: 0;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
`

const AccordionLabel = styled.div``

const MiniHeader = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${palette('lightBlue')};
  margin-bottom: 5px;
`

const AccordionContent = styled.div`
  padding: 16px;
`

function EmployeeAccordionItem({name, title, email}: Employee) {
  const [isOpen, setIsOpen] = useState<boolean>()

  return (
    <Accordion isOpen={isOpen}>
      <AccordionHead onClick={() => setIsOpen(!isOpen)}>
        <AccordionLabel>
          <MiniHeader>{title}</MiniHeader>
          <H4>{name}</H4>
        </AccordionLabel>
        <BtnWrapper>
          <OpenBtn aria-label="open">
            {isOpen ? (
              <ArrowUpSLineIcon
                size={30}
                color={styledTheme.palette.lightBlue}
              />
            ) : (
              <ArrowDownSLineIcon
                size={30}
                color={styledTheme.palette.lightBlue}
              />
            )}
          </OpenBtn>
        </BtnWrapper>
      </AccordionHead>
      <Expandable expanded={isOpen}>
        <AccordionContent>
          <H5>{title}</H5>
          <EmployeeEmail email={email} />
        </AccordionContent>
      </Expandable>
    </Accordion>
  )
}

function EmployeeAccordionList({employees}: Props) {
  return (
    <EmployeeAccordionWrapper>
      {employees.map((employee: Employee) => (
        <EmployeeAccordionItem key={employee.id} {...employee} />
      ))}
    </EmployeeAccordionWrapper>
  )
}

function EmployeeTable({employees}: Props) {
  return (
    <EmployeeTableWrapper>
      <Table>
        <TableRow>
          <TableHead>Nafn</TableHead>
          <TableHead>Starfstitill</TableHead>
          <TableHead>Netfang</TableHead>
          <TableHead>Senda póst</TableHead>
        </TableRow>
        {employees.map(({id, name, title, email}: Employee) => (
          <TableRow key={id}>
            <TableItem>{name}</TableItem>
            <TableItem>{title}</TableItem>
            <TableItem>{email}</TableItem>
            <TableItem>
              <Link href={`mailto:${email}`}>
                <IconWrapper>
                  <MailLineIcon
                    size={16}
                    color={styledTheme.palette.ui50Solid}
                  />
                </IconWrapper>
              </Link>
            </TableItem>
          </TableRow>
        ))}
      </Table>
    </EmployeeTableWrapper>
  )
}

export default function EmployeeSmallList({employees}: Props) {
  const {width} = useWindowSize()
  const isMobile = width < gridTheme.breakpoints.md

  return isMobile ? (
    <EmployeeAccordionList employees={employees} />
  ) : (
    <EmployeeTable employees={employees} />
  )
}
