import React, {useState} from 'react'

import AddLineIcon from 'remixicon-react/AddLineIcon'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {ifProp, palette} from 'styled-tools'

import {
  Page_page_content_AccordionListRecord_accordions as AccordionType,
  Page_page_content_AccordionListRecord_accordions_content as AccordionContent,
  Page_page_content_AccordionListRecord as AccordionListType,
} from '@festi/common/api/datocms/types/Page'
import {DatoStructuredText, Expandable} from '@festi/common/components/common'
import {H2, H3, PLarge} from '@festi/common/components/typography'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'

import {BlockWrapper} from '../layout'

const newDesign = settings.redesign

interface AccordionItemProps {
  title: string
  content: AccordionContent
  small: boolean
  darkMode: boolean
}

interface OpenBtnProps {
  isOpen: boolean
}

interface AccordionProps {
  isOpen: boolean
  darkMode: boolean
}

const ListWrapper = styled.div``

const AccordionHead = styled.div`
  padding: 10px 15px;
  cursor: pointer;
`

const expandedCSS = css`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), inset 0px -1px 0px #6dc4f2,
    inset 0px 1px 0px #6dc4f2;
`

const Accordion = styled.div<AccordionProps>`
  background-color: ${ifProp('darkMode', palette('blue'), palette('white'))};
  ${fluidRange('margin-bottom', '8px', '16px')};
  box-shadow: inset 0px -1px 0px #e6e8ee, inset 0px 1px 0px #e6e8ee;
  transition: box-shadow 1s;
  border-radius: ${newDesign ? '12px' : 'unset'};

  ${(props) => props.isOpen && expandedCSS}
`

const StructuredTextWrapper = styled.div`
  padding: 0 20px 1px;
  color: ${ifProp('darkMode', palette('white'), palette('blue'))};
`

const OpenBtn = styled.button<OpenBtnProps>`
  border: 0;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;

  &:hover {
    .remixicon-icon {
      fill: ${palette('lightBlue')};
    }
  }
`

const BtnWrapper = styled.div`
  text-align: right;
`

function AccordionItem({
  title,
  content,
  small = false,
  darkMode = false,
}: AccordionItemProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <Accordion isOpen={isOpen} darkMode={darkMode}>
      <AccordionHead onClick={() => setIsOpen(!isOpen)}>
        <Row alignItems="center">
          <Col col={10} sm={11}>
            <H3 size={small ? 5 : 4} darkMode={darkMode}>
              {title}
            </H3>
          </Col>
          <Col col={2} sm={1}>
            <BtnWrapper>
              <OpenBtn isOpen={isOpen} aria-label="open">
                {isOpen ? (
                  <SubtractLineIcon size={30} />
                ) : (
                  <AddLineIcon
                    size={30}
                    color={styledTheme.palette.ui50Solid}
                  />
                )}
              </OpenBtn>
            </BtnWrapper>
          </Col>
        </Row>
      </AccordionHead>
      <Expandable expanded={isOpen}>
        <StructuredTextWrapper>
          <DatoStructuredText content={content} darkMode={darkMode} />
        </StructuredTextWrapper>
      </Expandable>
    </Accordion>
  )
}

export default function AccordionList({
  title,
  description,
  accordions,
  small = false,
  darkMode = false,
}: AccordionListType): JSX.Element {
  return (
    <BlockWrapper darkMode={darkMode}>
      <Container>
        {(!!title || !!description) && (
          <Row alignItems="center">
            <Col col={12}>
              <H2 withGutter darkMode={darkMode}>
                {title}
              </H2>
              <PLarge withGutter darkMode={darkMode}>
                {description}
              </PLarge>
            </Col>
          </Row>
        )}
        <ListWrapper>
          <Row>
            {accordions?.map(({id, title, content}: AccordionType) => (
              <Col col={12} md={small ? 6 : 12} key={id} alignSelf="start">
                <AccordionItem
                  title={title}
                  content={content}
                  small={small}
                  darkMode={darkMode}
                />
              </Col>
            ))}
          </Row>
        </ListWrapper>
      </Container>
    </BlockWrapper>
  )
}
