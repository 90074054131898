import React from 'react'

import Link from 'next/link'
import styled from 'styled-components'
import {Image} from 'react-datocms'
import {palette} from 'styled-tools'

import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_LargeImageCardRecord_image} from '@festi/common/api/datocms/types/Page'
import {MainButton} from '@festi/common/components/buttons'

const CardWrapper = styled.div`
  position: relative;
  ${fluidRange('height', '400px', '520px')};
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${palette('backgroundBlue')};
`

const Inner = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  ${fluidRange('padding-left', '0px', '64px')};
  z-index: 99;

  @media (max-width: 768px) {
    gap: 20px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`

const Title = styled.div`
  font-family: 'ElkjopHeadline', sans-serif;
  text-align: left;
  ${fluidRange('font-size', '32px', '76px')};
  ${fluidRange('line-height', '34px', '76px')};
  font-weight: 0;
  color: ${palette('white')};
  text-transform: uppercase;
`

const Subtitle = styled.div`
  font-family: 'ElkjopSans', sans-serif;
  text-align: left;
  ${fluidRange('font-size', '16px', '20px')};
  ${fluidRange('line-height', '22px', '26px')};
  font-weight: 90;
  color: ${palette('white')};
`

const ImageWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  width: 68%;
  height: 100%;
  z-index: 10;

  @media (max-width: 768px) {
    width: 100%;
    height: 58%;
  }
`

const CardImage = styled(Image)`
  align-self: flex-end;
  width: 100%;
  max-height: 100%;
  border-radius: 8px;
`

const Overlay = styled.img`
  position: absolute;
  top: 0;
  ${fluidRange('left', '-380px', '-60px')};
  width: auto;
  height: 100%;
`

interface LargeImageCardProps {
  image: Page_page_content_GridSystemRecord_card_LargeImageCardRecord_image
  title: string | null
  subtitle: string | null
  link: string | null
}

export default function LargeImageCard({
  image,
  title,
  subtitle,
  link,
}: LargeImageCardProps): JSX.Element {
  return (
    <CardWrapper>
      <Inner>
        <Content>
          <TextWrapper>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </TextWrapper>

          <Link href={link || '#'}>
            <MainButton
              label="Sjá nánar"
              buttonVariant="negative"
              style={{paddingInline: '24px'}}
            />
          </Link>
        </Content>

        <ImageWrapper>
          <CardImage
            data={image?.responsiveImage}
            layout="responsive"
            objectFit="contain"
            objectPosition="center"
          />
        </ImageWrapper>
      </Inner>
      <Overlay alt="overlay" src="/images/card-overlay-shape.svg" />
    </CardWrapper>
  )
}
